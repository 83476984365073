<template>
  <div id="Configuration">
    <br /><br /><br />
    <div style="width: 60%">
      <h1 class="title">Demo Configuration</h1>
      <div class="panel">
        <h3 class="title">County ID</h3>
        <div style="text-align: right">
          <v-text-field id="countyId" type="text" v-model="countyId" />
        </div>
      </div>

      <div class="panel">
        <h3 class="title">Office ID</h3>
        <div style="text-align: right">
          <input
            type="text"
            style="display: inline; width: 70%; background-color: white"
            v-model="officeId"
          />
        </div>
      </div>
      <div class="panel">
        <h3 class="title">Drawer No</h3>
        <div style="text-align: right">
          <input
            type="text"
            style="display: inline; width: 70%; background-color: white"
            v-model="drawerNo"
          />
        </div>
      </div>
      <div class="panel">
        <h3 class="title">Workstation ID</h3>
        <div style="text-align: right">
          <input
            type="text"
            style="display: inline; width: 70%; background-color: white"
            v-model="workstationId"
          />
        </div>
      </div>
      <div class="panel">
        <h3 class="title">Invoice No</h3>
        <div style="text-align: right">
          <input
            type="text"
            style="display: inline; width: 70%; background-color: white"
            v-model="invoiceNo"
          />
        </div>
      </div>
      <div class="panel">
        <h3 class="title">CC Process Host</h3>
        <div style="text-align: right">
          <input
            list="apiList"
            v-model="monetraURL.host"
            style="display: inline; width: 70%; background-color: white"
          />
          <datalist id="apiList">
            <option value="https://cctest.bisonline.com" />
            <option value="https://cceng.bisonline.com" />
          </datalist>
        </div>
        <h3 class="title">CC Port</h3>
        <div style="text-align: right">
          <input
            list="apiList1"
            v-model="monetraURL.port"
            style="display: inline; width: 70%; background-color: white"
          />
          <datalist id="apiList1">
            <option value="8666" />
          </datalist>
        </div>
        <h3 class="title">CC basePath</h3>
        <div style="text-align: right">
          <input
            list="apiList2"
            v-model="monetraURL.basePath"
            style="display: inline; width: 70%; background-color: white"
          />
          <datalist id="apiList2">
            <option value="/" />
          </datalist>
        </div>
      </div>

      <div class="panel">
        <h3 class="title">API Host</h3>
        <div style="text-align: right">
          <input
            list="apiList3"
            v-model="apiURL.host"
            style="display: inline; width: 70%; background-color: white"
          />
          <datalist id="apiList3">
            <option value="https://apidemo.bisonline.com" />
            <option value="https://serverdev" />
            <option value="https://test-api.bisonline.com" />
            <option value="https://pp.vtrsapi.revenue.tn.gov" />
          </datalist>
        </div>
        <h3 class="title">API Port</h3>
        <div style="text-align: right">
          <input
            list="apiList4"
            v-model="apiURL.port"
            style="display: inline; width: 70%; background-color: white"
          />
          <datalist id="apiList4">
            <option value="6001" />
            <option value="6007" />
            <option value="5000" />
            <option value="8877" />
          </datalist>
        </div>
        <h3 class="title">API basePath</h3>
        <div style="text-align: right">
          <input
            list="apiList5"
            v-model="apiURL.basePath"
            style="display: inline; width: 70%; background-color: white"
          />
          <datalist id="apiList5">
            <option value="/star" />
            <option value="" />
          </datalist>
        </div>
      </div>
      <div class="panel">
        <h3 class="title">Demo State:</h3>
        <div style="text-align: right">
          <select
            style="display: inline; width: 70%; background-color: white"
            v-model="state"
          >
            <option value="TN">Tennessee</option>
            <option value="MN">Minnesota</option>
          </select>
        </div>
      </div>
      <div class="panel">
        <h3 class="title">Credit Card Device</h3>
        <br />
        <div style="text-align: right">
          Default Device
          <select
            id="device"
            style="display: inline; width: 66%"
            v-model="settingsObj.ccDevice"
          >
            <option value="">Select A Device</option>
            <option value="hid">HID MSR</option>
            <option value="uniterm">Ingenico RBA</option>
          </select>
        </div>
        <br />
      </div>
    </div>
    <v-btn @click="$router.go(-1)">Back</v-btn>
    <v-btn @click="save">Save</v-btn>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Configuration",
  data() {
    return {
      printers: "",
      defaultName: "",
      countyId: JSON.parse(JSON.stringify(this.$store.getters.countyId)),
      officeId: JSON.parse(JSON.stringify(this.$store.getters.officeId)),
      drawerNo: JSON.parse(JSON.stringify(this.$store.getters.drawerNo)),
      workstationId: JSON.parse(
        JSON.stringify(this.$store.getters.workstationId)
      ),
      invoiceNo: this.invoiceNo,
      state: JSON.parse(JSON.stringify(this.$store.getters.state)),
      apiURL: this.$store.state.BaseInfo.config.apiUrl,
      settingsObj: {},
      monetraURL: this.$store.state.BaseInfo.config.monetraUrl
    };
  },
  methods: {
    ...mapActions(["setHubConfiguration"]),
    async save() {
       
     this.$store.commit("countyId",this.countyId);
     this.$store.commit("state",this.state);
     this.$store.commit("apiUrl",this.apiURL);
     this.$store.commit("monetraUrl",this.monetraURL);

     this.$store.commit("officeId",this.officeId);
     this.$store.commit("drawerNo",this.drawerNo);
     this.$store.commit("workstationId",this.workstationId);
     this.$store.commit("invoiceNo",this.invoiceNo);

      try {
        const response = await this.setHubConfiguration(this.settingsObj);
       this.$root.$emit("push-alert",response.message, {
          color: "success"
        });
      } catch (error) {
        this.$root.$emit("push-alert", error.statusText, {
          color: "error"
        });
      }
    }
  },
  computed: {
    ...mapGetters({
      invoiceNo: "invoiceNo"
    })
  },
  mounted() {
    try {
      this.$hubapp.getHubConfigs().then(msg => {
        this.settingsObj = msg;
      });
    } catch (error) {
      this.$store.dispatch("setGlobalAlertState", {
        title: "Error",
        description:
          "There was a problem retrieving saved device settings. Please ensure the hub app is running.",
        icon: "error"
      });
    }
  }
};
</script>

<style scoped></style>
